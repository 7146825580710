import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Layout, LinkButton, SEO } from "../components";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

const options = [
  {
    name: "WhatsApp",
    description: (props) => (
      <>
        <div {...props}>Envíanos un mensaje por WhatsApp</div>
      </>
    ),
    action: "Envíanos un mensaje",
  },
  {
    name: "Teléfono",
    description: (props) => (
      <>
        <div {...props}>
          ¿Prefieres contactanos por teléfono?{" "}
          <span className="block font-medium mt-2 text-blue-500">terrr</span>
        </div>
      </>
    ),
    action: "Llámanos",
  },
];

const AlliesPage = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        ...SiteInformationFragment
      }
    }
  `);

  const metadata = site.siteMetadata;
  const metaPage = "aliados";
  const metaTitle = "Aliados";
  const metaDescription =
    "Conoce las empresas con las que tenemos alianzas y consulta las promociones disponibles";

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": metadata.siteUrl,
        url: metadata.siteUrl,
        name: metadata.title,
        description: metaDescription,
        inLanguage: "es-MX",
      },
      {
        "@type": "ImageObject",
        "@id": `${metadata.siteUrl}${metadata.defaultOgImage}#primaryimage`,
        inLanguage: "es-MX",
        url: `${metadata.siteUrl}${metadata.defaultOgImage}`,
      },
      {
        "@type": "WebPage",
        "@id": `${metadata.siteUrl}/${metaPage}/#webpage`,
        url: `${metadata.siteUrl}/${metaPage}`,
        name: `${metaTitle} · ${metadata.title}`,
        isPartOf: { "@id": metadata.siteUrl },
        primaryImageOfPage: {
          "@id": `${metadata.siteUrl}${metadata.defaultOgImage}`,
        },
        breadcrumb: {
          "@id": `${metadata.siteUrl}/${metaPage}/#breadcrumb`,
        },
        inLanguage: "es-MX",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: [`${metadata.siteUrl}/${metaPage}`],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": `${metadata.siteUrl}/${metaPage}#breadcrumb`,
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": metadata.siteUrl,
              url: metadata.siteUrl,
              name: "Inicio",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": `${metadata.siteUrl}/${metaPage}`,
              url: `${metadata.siteUrl}/${metaPage}`,
              name: `${metaTitle} · ${metadata.title}`,
            },
          },
        ],
      },
    ],
  };

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        schemaMarkup={schemaMarkup}
      />
      <div className="bg-white py-24">
        <div className="mx-auto px-8 max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl text-center">
          <h1 className="font-bold text-gray-900 tracking-tight text-4xl md:text-5xl text-center">
            Aliados
          </h1>
          <div className="mt-16">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
              {/* Costco Start */}
              <div className="flex flex-col bg-white rounded-lg shadow-md border border-gray-100 mt-8">
                <div className="flex-1 p-8 -mt-8">
                  {/* <div className="p-4 inline-block bg-white rounded-lg transform -translate-y-1/2">
                   <img className="h-14" src={CostcoLogo} alt="Logo Costco" />
                  </div>
                  */}
                  <h2 className="text-xl font-medium text-gray-900 -mt-8">
                    Costco
                  </h2>
                  <p className="mt-5 text-base text-gray-500 flex-1">
                    <span className="block">
                      Con nuestra alianza con Costco, podrás gozar de reembolsos
                      en tu tarjeta de crédito de dicha tienda además del
                      respaldo de garantía de Servicio al Cliente.
                    </span>
                    <span className="block mt-4 font-medium text-center">
                      ¡Te invitamos a ver las promociones que muy pronto
                      tendremos en su página web para ti!
                    </span>
                  </p>
                </div>
              </div>
              {/* Costco End */}

              {/* Citibanamex Start */}
              <div className="flex flex-col bg-white rounded-lg shadow-md border border-gray-100 mt-8">
                <div className="flex-1 p-8 -mt-8">
                  {/* <div className="p-4 inline-block bg-white rounded-lg transform -translate-y-1/2">
                   <img className="h-14" src={CostcoLogo} alt="Logo Costco" />
                  </div>
                  */}
                  <h2 className="text-xl font-medium text-gray-900 -mt-8">
                    Citibanamex
                  </h2>
                  <p className="mt-5 text-base text-gray-500 flex-1">
                    <span className="block">
                      ¿Te gustaría probar paneles solares sin tener que invertir
                      en el sistema y además tener 2 meses gratis?
                    </span>
                    <br></br>
                    <span>
                      Si eres cliente Citibanamex ¡Ahora puedes hacerlo con
                      Bright!
                    </span>
                    <span className="block mt-4 font-medium text-center">
                      <i>Promoción válida al 30/09/2022</i>
                    </span>
                  </p>
                </div>
                <Link to="/citibanamex2022">
                  <div className="flex flex-col justify-between bg-gray-50 rounded-lg">
                    <div className="flex items-center justify-center py-3 text-base font-medium text-blue-500">
                      Cotizar
                      <ArrowRightIcon className="h-4 ml-2" />
                    </div>
                  </div>
                </Link>
              </div>
              {/* Costco End */}
            </div>
          </div>
        </div>
      </div>
      {/* Contact Us Start */}
      <div className="bg-gray-50">
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-blue-500 sm:text-4xl">
            ¿Tienes preguntas?
          </h2>

          <div className="mx-auto mt-8 max-w-xs w-56">
            <LinkButton shadow arrow size="large" to="/contacto">
              Contáctanos
            </LinkButton>
          </div>
        </div>
      </div>
      {/* Contact Us End */}
    </Layout>
  );
};

export default AlliesPage;
